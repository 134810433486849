/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getEntryBrandCookie } from '../attribution/attributionHelpers';
import { getFeatureFlag } from '../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getEnterpriseId } from '../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import useBrowserUUID from '../useBrowserUUID';

/**
 * @description - used to make a useQuery call and acts like a interceptor for experiment service and aggregator service.
 * @param { string } queryName - findCategoryById|findCategoryPageByUrl|findProductsBySearchTerm
 * @returns { object } - {  targeting, context, isGraphqlTargetingEnabled  }
 */

const useExperimentServiceAttributes = ({
    queryName,
}) => {
    const location = useLocation();
    const isExperimentsServiceEnabled = useSelector(getFeatureFlag('is-experiments-service-enabled'))?.[queryName];
    const isGraphqlTargetingEnabled = useSelector(getFeatureFlag('is-graphql-targeting-enabled'));
    const enterpriseId = useSelector(getEnterpriseId);

    const isClient = typeof window !== 'undefined';
    const browserUUID = (isClient && window.localStorage) ? useBrowserUUID() : '';
    const pageType = (isClient && window.pageType) ? window.pageType : '';
    const pageURL = (isClient && window.location) ? window.location.href : '';

    const experimentService = Boolean(isExperimentsServiceEnabled && isClient);

    const entryBrand = getEntryBrandCookie() || '';

    const targeting =  experimentService ? [
        { key: 'entryBrand', value: entryBrand },
        { key: 'url', value: location.pathname },
        { key: 'browserUUID', value: browserUUID },
        { key: 'EID', value: enterpriseId },
        { key: 'pageType', value: queryName === 'findProductsBySearchTerm' ? 'searchterm' : pageType },
    ] : [];

    // to handle re-render when URL change for product facets
    // pageURL is not required for findCategoryPageByUrl, Since its passed as separate variable (url)
    if (experimentService && queryName !== 'findCategoryPageByUrl') {
        targeting.push({ key: 'pageURL', value: pageURL });
    }

    const context = { api: experimentService ? 'experiments' : 'aggregator' };
    return {
        targeting, context, isGraphqlTargetingEnabled, experimentService,
    };
};

export default useExperimentServiceAttributes;
